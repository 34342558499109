import React, { FC, useState, useEffect } from "react";
import {
  Container,
  TitleBig,
  BodyWrapper,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  EstimateSubTitle,
  EstimateDesc,
  PropContainer,
  PropsWrapper,
  Divider,
  Prop
} from './styles'
import { Tabs, BackArrow } from "../../../../components";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Configure from "./configure";
import FinallyCreate from "./finally";
import { useQueryParam, StringParam } from 'use-query-params';
import { useAuth } from "../../../../context/user.context";
import { formatCurrency } from "../../../../utils/display-price";
import { useGpu } from "../../../../context/gpuContext";
import { formatQuotasRam } from "../../../../utils/sizes";



const CreateAndEditGpuServer:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const {
    configureData,
    plans
  } = useGpu()
  const {user} = useAuth()

  useEffect(() => {
    if(!step){
      setStep('configure')
    }
    if(tabs.find((item:any) => item.slug === step) === -1){
      setStep('configure')
    }
  },[])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("CONFIGURE_INSTANCE"),
      slug:'configure',
      id:'1'
    },

  ])
  const plan = plans.find((plan:any) => plan?.id === configureData?.type)
  return(
    <Container>
      <BackArrow
        onClick={() => navigate('/gpu/servers')}
        title={t("BACK_TO_SERVERS")}
      />
      <TitleBig>{t("CREATE_NEW_GPU_SERVER")}</TitleBig>
      <div style={{width:'100%'}}>
        <Tabs
          tabs={tabs}
          size='display'
          checked
          isSteps
          location={step}
        />
      </div>
      <BodyWrapper>
        {step === 'configure' ? 
          <Configure/>
        : null}
        {step === 'finally' ? 
          <FinallyCreate/>
          : null
        }
        <EstimateContainer>
          <EstimateTitle>
            {t("ESTIMATED_COST")}
          </EstimateTitle>
          {
            plan ? 
              <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {formatCurrency((+plan?.hourly_price).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}
                  </EstimateSum>
                  <PerHour>{t("HOURS")}</PerHour>
                </div>
                <div style={{display:'flex', alignItems:'center'}}>
                  <EstimateSum>
                    {formatCurrency((+plan?.montly_price).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}
                  </EstimateSum>
                  <PerHour>{t("MONTH")}</PerHour>
                </div>
              </div>
            :
            <PerHour>
              {t("TO_DISPLAY_PRICE_SELECT_PLAN")}
            </PerHour>
          }
          <Divider/>
          {plan?.type ?
            <>
              <EstimateSubTitle>
                {t("TYPE_OF_INSTANCE")}
              </EstimateSubTitle>
              <div style={{maxWidth:'200px'}}>
                <EstimateDesc>
                  {plan?.name}
                </EstimateDesc>
                <PropsWrapper>
                  <PropContainer>
                    <Prop>
                      {plan?.cpu} CPU
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {formatQuotasRam(plan?.ram)} RAM
                    </Prop>
                  </PropContainer>
                </PropsWrapper>
                <PropsWrapper>
                  <PropContainer>
                    <Prop>
                      {plan?.gpu} GPU
                    </Prop>
                  </PropContainer>
                  <PropContainer>
                    <Prop>
                      {plan?.gpu_label}
                    </Prop>
                  </PropContainer>
                </PropsWrapper>
              </div>
            </>
          : null}
          <PropsWrapper>
            <div>
              <EstimateSubTitle>
                {t("STORAGE")}
              </EstimateSubTitle>
              <EstimateDesc>
                  {plan?.disk} GB
              </EstimateDesc>
            </div>
          </PropsWrapper>
        </EstimateContainer>
      </BodyWrapper>
    </Container>
  )
}

export default CreateAndEditGpuServer