import React, { FC, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes, useNavigate, useLocation, useParams } from "react-router-dom";
import { BackArrow, Button, ModalVariables, Tabs, TopProgress } from "../../../../components";
import { BodyDefault } from "../../../../components/typography/styled";
import { icons } from "../../../../icons";
import {
  Container,
  HeadWrapper,
  RightHeadContainer,
  InstanceName,
  InstanceHeadInfo,
  InstanceDot,
  InstanceStatus,
  InstanceType,
  NavWrapper,
  StyledIcon,
  StyledIconMenu
} from './styles'
import Overview from "./overview";
import Metrics from './metrics'
import { gpuService, instanceService } from "../../../../api";
import { useUi } from "../../../../context/ui.context";
import Console from "./console";

const InstancePage:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [options, setOptions] = useState(false)
  const {gpuInstanceId} = useParams()
  const [instance, setInstance] = useState<any>(null)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [volumes, setVolumes] = useState<any[]>([])
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<string>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>('')
  const _updateInstance = () => {
    if(gpuInstanceId) {
      gpuService.getServerById(gpuInstanceId)
      .then((res) => {
        setPageLoading(false)
        setInstance(res?.data)
      })
      .catch((err) => {
        viewAlert({
          severity: 'error',
          message: err?.response?.data?.detail || t("ERROR_OCCURRED"),
        })
        navigate('/instances')
      })
    }
  }
  // clg
  useEffect(() => {
    _updateInstance()
    const interval = setInterval(() => {
      _updateInstance()
    },5000)
    return () => {
      clearInterval(interval)
    }
  },[gpuInstanceId])
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("OVERVIEW"),
      slug:'overview',
      id:'1'
    },

    {
      title:t("METRICS"),
      slug:'metrics',
      id:'8'
    },
    {
      title:t("CONSOLE"),
      slug:'console',
      id:'3'
    },
  ])


  
  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  const optionsRef:any = useRef()
  const dropsRef:any = useRef()
  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    },[]);
  const handleClickOutside = (event:any) => {
    if (optionsRef && !optionsRef.current.contains(event.target) && !dropsRef.current.contains(event.target)){
      setOptions(false)
    }
  }

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }



  const closeModal = async (values: any) => {
    let res:any; // объявляем переменную res
    try {
      setLoading(true);
      switch (modalType) {

        // case 'change-password':
        //   res = await instanceService.changeRootPassword(instance.id, values)
        //   if (res?.data?.detail) {
        //     viewAlert({
        //       severity: 'info',
        //       message: res.data?.detail,
        //     });
        //   }
        //   // navigate('/instances')
        //   break;
        case 'delete':
          res = await instanceService.deleteInstance(instance.id)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          navigate('/instances')
          break;

        case 'rename':
          res = await instanceService.renameInstance(instance.id, values);
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        default:
          break;
      }
  
      _updateInstance();
      setViewModal(false);
      setActiveAnimation(false);
    } catch (e: any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <TopProgress loading={!pageLoading && loading}/>
      <BackArrow onClick={() => navigate('/instances')} title={t("BACK_TO_INSTANCES")}/>
      <HeadWrapper>
        <InstanceName>
          {instance?.name}
        </InstanceName>
      </HeadWrapper>
      <InstanceHeadInfo>
        {/* {instance.display_status === 'Verify Resize' || instance.display_status === "Підтвердьте зміну розміру" ? 
          <img src={icons.Warning}/> 
        : instance?.display_task !== null ? 
            <InstanceDot style={{backgroundColor:'#E8B533'}}/>
          :
            <InstanceDot active={instance.status}/>
        }
        {instance?.display_task !== null ? 
          <InstanceStatus style={{color:'#E8B533'}}>
            {instance.display_status}
          </InstanceStatus>
        :
          <InstanceStatus active={instance.status}>
            {instance.display_status}
          </InstanceStatus>
        } */}

        <InstanceType>
          {t("TYPE")}
        </InstanceType>
        <BodyDefault>
          {instance?.flavor?.name}
        </BodyDefault>
      </InstanceHeadInfo>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <Routes>
        <Route path="/" element={<Navigate replace to="overview" />} /> 
        <Route path="*" element={<Navigate replace to="overview" />} /> 
        <Route path='/overview' element={<Overview instance={instance}/>}/>
        <Route path='/console' element={<Console instance={instance}/>}/>
        <Route path='/metrics' element={<Metrics instance={instance}/>}/>

      </Routes>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        alertSubTitle={alertSubTitle}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={instance}
        instanceId={instance.id}
        loading={loading}
        dataToEdit={instance}
      />
    </Container>
  )
}

export default InstancePage
