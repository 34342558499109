import React, {FC, useRef, useState, useEffect} from 'react'
import {
  DropDown,
  OptionsWrapper,
  Option,
  Container,
  ImgArrow,
  ItemText,
  ItemTextSmall,
  PropContainer,
  Prop,
  PropsWrapper,
  PropMulti,
  SelectInput,
  ItemFlavor,
  Categorytitle,
  ItemTextTitle
} from './styles'
import ArrowDown from './arrow-down.svg'
import Label from '../label';
import { icons } from '../../icons';
import { ErrorText } from '../input/styles';
import { BodyDefault, VerySmall } from '../typography/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

type SelectProps = {
  data: any[],
  selectedValue:any;
  onChange: (selected:any) => void;
  isSmall?: boolean,
  title:string,
  toolTip?:any,
  multi?:boolean,
  disabled?:boolean,
  customStyles?:any,
  customDropStyles?:any,
  disabledText?:string,
  multipleOnChange?:(value:any) => void,
  autoComplete?:boolean,
  fieldName?:string,
  errors?:any,
  touched?:any,
  bootImage?:any,
  placeholder?:any,
  hasSearch?:boolean,
  categories?:any[],
  tooltipText?:string,
  required?:boolean,
  test_id?:string,
  isRegion?:boolean,
}
type ListItem = {
  title:string,
  id:any,
  options?:any[],
  icon?:any,
  isSmall?: boolean,
  onChange?: (selected:any) => void;
  value?:any,
}
const Select:FC<SelectProps> = (props) => {
  const [t] = useTranslation()
  const inputRef:any = useRef()
  const inputRefSearch:any = useRef()
  const [active, setActive] = useState<boolean>(false)
  const [searchString, setSearchString] = useState<string>('')
  const [selected, setSelected] = useState<any>(null)
  const [height, setheight] = useState<any>()

  useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
				document.removeEventListener('click', handleClickOutside, true);
		};
  },[]);


  const handleClickOutside = (event:any) => {
    if (selectRef && !selectRef.current.contains(event.target)){
      setActive(false)
    }
  }
  const {
    data,
    selectedValue,
    onChange,
    isSmall,
    title,
    toolTip=false,
    customStyles,
    multi=false,
    disabled,
    errors,
    fieldName,
    touched,
    bootImage,
    placeholder,
    multipleOnChange,
    hasSearch,
    categories,
    customDropStyles,
    tooltipText,
    required,
    test_id,
    isRegion,
  } = props
  //@ts-ignore
  const fieldError = errors?.[fieldName];
  //@ts-ignore
  const fieldTouched = touched?.[fieldName];
  const changeValue = (value:any) => {
    if(value?.ocupado){
      return
    }
    if(multi && multipleOnChange){
      multipleOnChange(value)
      setSearchString('')
    } else if(hasSearch) {
      onChange(value)
      setActive(false)
      // setSearchString(selected?.label)

    } else {
      onChange(value)
      setActive(false)
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [selectedValue, data, searchString]);
  const handleEsc = (event:any) => {
    if (
      event.keyCode === 8 &&
      multi &&
      selectedValue?.length !== 0 &&
      searchString?.length === 0
    ) {
     changeValue(selectedValue?.[selectedValue?.length - 1])
   }
  };
  useEffect(() => {
    setSelected(data?.length > 0 && data.find((item:ListItem) => (item.id !== undefined && item.id === selectedValue) || item.value === selectedValue))
  },[selectedValue])
  useEffect(() => {
    if(searchString.length !== 0){
      setActive(true)
    }
  },[searchString])
  useEffect(() => {
    setheight(selectRef?.current?.offsetHeight)
  })
  const selectRef:any = useRef()
  const dropRef:any = useRef()
  const droppedRef = useRef<HTMLDivElement>(null);
  const checkSelectContainerPosition = () => {
    const windowHeight = window.innerHeight;
    const selectContainer = dropRef.current;
    const containerPosition = selectContainer?.getBoundingClientRect();
    if(containerPosition === undefined){
      return 
    }
    if (containerPosition?.bottom && (droppedRef.current?.getBoundingClientRect()?.height + containerPosition.bottom) > windowHeight) {
      droppedRef.current?.style.setProperty('bottom', `${height-18}px`);

    } else {
      droppedRef.current?.style.setProperty('bottom', `auto`);
      droppedRef.current?.style.setProperty('margin-top', '6px');
    }
  };
  useEffect(() => {
    checkSelectContainerPosition(); // Проверка позиции при открытии страницы
    const handleScroll = () => {
      checkSelectContainerPosition(); // Проверка позиции при скроллировании
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [window.innerHeight, active]);
  const theme:any = useTheme()
  return (
    <Container  customStyles={customStyles} ref={selectRef}>
      {isSmall ? null :
        <div style={{marginBottom:"4px"}}>
          <Label required={required} tooltipText={tooltipText} toolTip={toolTip} title={title}/>
        </div>
      }
      <DropDown
        style={customDropStyles}
        ref={dropRef}
        isSelectedValue={selectedValue}
        error={fieldError}
        data-test-id={test_id}
        isRegion={isRegion}
        active={active}
        onClick={() => {
          if(!active && multi){
            inputRef.current.focus()
            setActive(!active)
          }
          if(!active && hasSearch){
            onChange('')
            setActive(!active)
            inputRefSearch.current.focus()
          }
          if(!active){
            setActive(!active)
          } else {
            setActive(!active)
          }
        }}
        isSmall={isSmall}
        disabled={disabled}
        multi={multi}
      >
      {
        !selected && !multi  ? 
          hasSearch ?
            <SelectInput ref={inputRefSearch} onChange={(e) => setSearchString(e.target.value)} value={searchString}/>
          :
          <div style={{display:'flex', alignItems:"center", width:"100%"}}>
            <ItemText style={{whiteSpace:"nowrap", opacity:'0.5', width:"100%"}}>
              {placeholder || t("CHOOSE")}
            </ItemText>
          </div>
        : 
          null
      }
      {isRegion && 
        <VerySmall style={{color:theme.palette[800], textTransform:"uppercase", fontSize:"8px", lineHeight:'9.68px'}}>
          {t("REGION")}
        </VerySmall>
      }
      {multi ? 
        <div style={{display:'flex', alignItems:"center", justifyContent:'space-between', width:'100%'}}>
          {selectedValue ?
            <PropsWrapper>
              {selectedValue?.map((item:any) => (
                <PropContainer style={{height: '30px', padding:'5px 4px 5px 6px'}}>
                  <PropMulti>
                    {item.label}
                  </PropMulti>
                  <ImgArrow onClick={() => changeValue(item)} active={false} style={{width:'16px', marginLeft:'3px'}} src={icons.CloseIcon}/>
                </PropContainer>
              ))}
              <SelectInput ref={inputRef} onChange={(e) => setSearchString(e.target.value)} value={searchString}/>
            </PropsWrapper>
          : null
          }
          <ImgArrow active={active && !disabled} src={ArrowDown}/>
        </div>
      :
        <div style={{display:'flex', alignItems:"center", width:'100%', justifyContent:'space-between'}}>
          <div style={{display:'flex', alignItems:"center"}}>
            {selected?.icon ?
              <img style={{marginRight:'4px'}} src={selected?.icon} />
            : null}
            <ItemTextTitle>
              {
              selected?.customItem &&
                selected?.type === 'flavor' ?
                  <ItemLabelFlavor item={selected.customItem} />
              :
               selected?.title || selected?.label}
            </ItemTextTitle>
          </div>
            <div style={{display:'flex', alignItems:"center"}}>
              {selected?.options ?
                <PropsWrapper>
                  {selected?.options.map((item:any) => (
                    <PropContainer>
                      <Prop>
                        {item}
                      </Prop>
                    </PropContainer>
                  ))}
                </PropsWrapper>
              : null
              }
              <ImgArrow active={active && !disabled} src={ArrowDown}/>
            </div>
          </div>
        }
      </DropDown>
      {active && !disabled ? 
        <OptionsWrapper ref={droppedRef} multi={multi} height={height} isSmall={isSmall}>
          {
            searchString?.length > 0 ?
              data?.length > 0 && data.filter((item) => item.label.toLowerCase().indexOf(searchString.toLowerCase()) !== -1)?.length > 0 ?
               data.filter((item) => item.label.toLowerCase().indexOf(searchString.toLowerCase()) !== -1).map((item:ListItem) => (
                <Item
                  item={item}
                  isSmall={isSmall}
                  selectedValue={selectedValue}
                  changeValue={changeValue}
                  multi={multi}
                />
              )) :
              <Option>
                <ItemText style={{whiteSpace:"nowrap"}}>
                  {t("NO_DATA_SEARCHED")}
                </ItemText>
              </Option>
            :
                categories && categories?.length > 0 ? categories?.map((category) => (
                  <div style={{padding:"12px 8px 12px 10px"}}>
                    <Categorytitle>
                      {category.name}
                    </Categorytitle>
                    <>
                      {data?.length > 0 && data.filter((select:any) => select.category === category.value).map((item:ListItem) => (
                        <Item
                          //@ts-ignore
                          // item={<ItemLabelFlavor item={item.customItem} image={bootImage} />}
                          item={item}
                          isSmall={isSmall}
                          bootImage={bootImage}
                          selectedValue={selectedValue}
                          changeValue={changeValue}
                          multi={multi}
                        />
                      ))}
                    </>
                  </div>
                )) 
              :
                data?.length > 0 ? data.map((item:ListItem) => (
                  <Item
                    //@ts-ignore
                    // item={<ItemLabelFlavor item={item.customItem} image={bootImage} />}
                    item={item}
                    isSmall={isSmall}
                    bootImage={bootImage}
                    selectedValue={selectedValue}
                    changeValue={changeValue}
                    multi={multi}
                  />
                ))
                :
                <Option>
                  <ItemText style={{whiteSpace:"nowrap"}}>
                    {t("NO_DATA")}
                  </ItemText>
                </Option>
          }
        </OptionsWrapper>
      : null}
      <ErrorText> 
        {fieldError}
      </ErrorText>
    </Container>
  )
}

export default Select


const Item:FC<any> = ({
  item,
  isSmall,
  selectedValue,
  changeValue,
  multi,
  bootImage
}) => {
  console.log('bootImage', bootImage);
  const blocked = bootImage ? (item.memory_mb < bootImage.min_ram || item.root_gb < bootImage.min_disk || bootImage.disksCount < item.min_disks) : false
  return (
    <Option
      key={item.id}
      isSmall={isSmall}
      // active={item.id === selectedValue}
      active={(item.id !== undefined && item.id === selectedValue) || item.value === selectedValue}
      onClick={() => multi ? changeValue(item) : item.blocked || blocked ? null : changeValue(item.id || item.value)}
    >
      <div 
        style={{
          width:'100%',
          display:'flex',
          alignItems:"flex-start",
          gap:'5px',
          // justifyContent:'space-between'
        }}
      >
        <div style={{display:'flex', alignItems:"center"}}>
          {item.icon ?
            <img style={{marginRight:'4px'}} src={item.icon} />
          : null}
          {isSmall ? 
            <ItemTextSmall>
              {item.title || item.add_label ? item.add_label : item.label}
            </ItemTextSmall>
          :
            <ItemText>
              {
              item.customItem &&
              item.type === 'flavor' ?
                  <ItemLabelFlavor blockedDown={item.blockedDown} blocked={blocked || item.blocked} item={item.customItem}/>
              :
              item.type === 'disk_layout' ? 
              <ItemLabelDiskLayout blockedDown={item.blockedDown} blocked={blocked || item.blocked} item={item}/>
              :
              item.title || item.add_label ? item.add_label : item.label}
            </ItemText>
          }
        </div>
        <div style={{display:'flex', alignItems:"center"}}>
          {item?.options && !multi ?
            <PropsWrapper>
              {item?.options.map((item:any) => (
                <PropContainer>
                  <Prop>
                    {item}
                  </Prop>
                </PropContainer>
              ))}
            </PropsWrapper>
          : null
          }
        </div>
      </div>
    </Option>
  )
}
export const ItemLabelDiskLayout:FC<any> = ({item, blocked, blockedDown, withoutPrice}) => {
  const [t] = useTranslation()
  return (
    <div style={{display:'flex', flexDirection:"column"}}>
      <ItemFlavor style={{justifyContent:'flex-start'}} block={blocked}>
        <BodyDefault>
          {item?.label}
        </BodyDefault>
        <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
          <PropContainer>
            <Prop>
            {t("MIN_DISKS")}-{item?.min_disks || 1}
            </Prop>
          </PropContainer>
        </div>
      </ItemFlavor>
      {blocked && !blockedDown ? <ErrorText style={{fontSize:'12px', marginTop:'0px'}}>{t("INCOMPATIBLE_DISK_LAYOUT")}</ErrorText> : null}
    </div>
  )
}

export const ItemLabelFlavor:FC<any> = ({item, blocked, blockedDown, withoutPrice}) => {
  const [t] = useTranslation()
  return (
    <div style={{display:'flex', flexDirection:"column"}}>
      <ItemFlavor block={blocked}>
        <BodyDefault>
          {item?.name}
        </BodyDefault>
        <div style={{display:'flex', alignItems:'center', gap:'6px'}}>
          <PropContainer>
            <Prop>
              {item?.vcpus} CPU
            </Prop>
          </PropContainer>
          <PropContainer>
            <Prop>
              {item?.memory_gb}GB RAM
            </Prop>
          </PropContainer>
          {!withoutPrice &&
            <PropContainer>
              <Prop>
                {item.estimate}
              </Prop>
            </PropContainer>
          }
        </div>
      </ItemFlavor>
      {blocked && !blockedDown ? <ErrorText style={{fontSize:'12px', marginTop:'0px'}}>{t("INCOMPATIBLE_FLAVOR")}</ErrorText> : null}
    </div>
  )
}