import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BodyForm,
  InputsWrapper,
  SelectWrapper,
  SelectRow,
  RefreshWrapper,
  RefreshText,
  RotateImg,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Input,
  Select,
  Button,
  ModalVariables,
  TopProgress,
  MatchPassword,
} from "../../../../../components";
import { useQueryParam, StringParam } from 'use-query-params';
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import ContentLoader from 'react-content-loader'
import { useTheme } from "styled-components";
import { useInstance } from "../../../../../context/instanceContext";
import { useGpu } from "../../../../../context/gpuContext";
import Refresh from './restart.svg'


const Configure:FC = () => {
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [activeConfirm, setActiveConfirm] = useState<boolean>(false)
  const [activeButton, setActiveButton] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const InputRef:any = useRef()
  const {
    pageLoading,
    plans,
    configureData,
    setConfigureData,
    scripts
  } = useGpu()
    const {
      sshKeys,
      loadingRefresh,
      getCreateOptions,
    } = useInstance()
  const closeModal = (isSave?:boolean) => {
    setViewModal(false)
    setActiveAnimation(false)
    if(!isSave){
      removeItemFromLs('gpu.configure')
    }
    navigate("/instances")
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setModalType('')
    setActiveAnimation(false)
  }
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  useEffect(() => {
    getCreateOptions()
  },[])
  const navigate = useNavigate()
  const [t] = useTranslation()
  const [step, setStep] = useQueryParam('step', StringParam);
  const [initialValues, setInitialValues] = useState<any>({
    name:getDataToLs({key:'gpu.configure'})?.name,
    type:getDataToLs({key:'gpu.configure'})?.type,
    os:getDataToLs({key:'gpu.configure'})?.os,
    ssh:getDataToLs({key:'gpu.configure'})?.ssh,
    password:getDataToLs({key:'gpu.configure'})?.password || '',
    script_id:getDataToLs({key:'gpu.configure'})?.script_id || '',
    env:getDataToLs({key:'gpu.configure'})?.env || '',
  }) 
  const onSubmit = (values:any) => {
    setStep("finally")
  }

  const regLetter = /^(?=.*[a-z])(?=.*[A-Z])/
  const regNumbers = /^(?=.*[0-9])/
  const regSymbols = /^(?=.*[!@#$%^&*()_=+§`~<>?])/
  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setErrors,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string()
      .required(`${t('REQUIRED_FIELD')}`)
      .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      type: yup.string().required(`${t('REQUIRED_FIELD')}`),
      os: yup.string().required(`${t('REQUIRED_FIELD')}`),
      ssh: yup.string().required(`${t('REQUIRED_FIELD')}`),
      password: yup.string()
        .required(`${t('REQUIRED_FIELD')}`)
        .min(8, `${t("PASSWORD_DID_NOT_PASS")}`)
        .max(24, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regLetter, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regNumbers, `${t("PASSWORD_DID_NOT_PASS")}`)
        .matches(regSymbols, `${t("PASSWORD_DID_NOT_PASS")}`),
      script_id:yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  useEffect(() => {
    setDataToLs({key:'gpu.configure', data:values})
    setConfigureData(values)
  },[values])
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  const theme:any = useTheme()

  if(pageLoading) return (
    <>
      <TopProgress loading={pageLoading}/>
      <ContentLoader backgroundColor={theme.palette[10]} viewBox="0 0 500 800" height={800} width={500}>
        <rect x="0" y="45" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="125" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="205" rx="4" ry="4" width="343" height="48" />
        <rect x="0" y="285" rx="4" ry="4" width="343" height="48" />
        {/* <rect x="0" y="335" rx="4" ry="4" width="543" height="308" /> */}
        <rect x="0" y="365" rx="4" ry="4" width="120" height="40" />
        <rect x="100" y="445" rx="4" ry="4" width="80" height="40" />
        <rect x="0" y="445" rx="4" ry="4" width="80" height="40" />
      </ContentLoader>
    </>
  )
  return (
    <BodyForm data-test-id='instance-gpu-configure-container'>
      <InputsWrapper>
        <Input
          required
          fieldName='name'
          title={t("NAME_OF_INSTANCE")}
          placeholder=''
          {...formikProps}
        />
        <Select
          required
          toolTip={false}
          data={plans}
          selectedValue={values['type']}
          onChange={(id:any) => handleChange('type')(id)}
          test_id={'instance-gpu-type-select'}
          title={t("TYPE_OF_INSTANCE")}
          placeholder={t("CHOOSE_TYPE")}
          fieldName='type'
          {...formikProps}
        />
        {values['type'] &&
          <Select
            required
            toolTip={false}
            data={plans?.find((plan:any) => plan.id === values['type'])?.os_availables}
            selectedValue={values['os']}
            onChange={(id:any) => handleChange('os')(id)}

            test_id={'instance-os-select'}
            title={t("OS")}
            fieldName='os'
            {...formikProps}
          />
        }
        <div style={{position:'relative'}}>
          <Input
            required
            title={t('ROOT_PASSWORD_LABEL')}
            fieldName='password'
            placeholder={t('ROOT_PASSWORD_LABEL')}
            onFocus={() => setActiveConfirm(true)}
            onFocusOut={() => setActiveConfirm(false)}
            onBlur={handleBlur}
            ref={InputRef}
            isPassword
            {...formikProps}
          />
          <MatchPassword
            setEnabled={setActiveButton}
            validateString={values['password']}
            active={activeConfirm}
          />
        </div>
        <Select
          toolTip={false}
          data={scripts}
          selectedValue={values['script_id']}
          onChange={(id:any) => handleChange('script_id')(id)}
          test_id={'script-id-gpu-select'}
          title={t("SCRIPT")}
          placeholder={t("CHOOSE_SCRIPT")}
          fieldName='script_id'
          {...formikProps}
        />
        <Input
          isTextArea
          fieldName='env'
          title={t("ENV")}
          placeholder=''
          {...formikProps}
        />
      </InputsWrapper>
      <SelectWrapper>
        <SelectRow>
          <div style={{width:'360px'}}>
            <Select
              toolTip={false}
              data={sshKeys}
              required
              selectedValue={values['ssh']}
              test_id={'instance-ssh-select'}
              onChange={(e) => {
                handleChange('ssh')(e)}}
              title={t("SSH_KEY")}
              disabled={sshKeys.length === 0}
              {...formikProps}
              fieldName='ssh'
            />
          </div>
          <RefreshWrapper>
            <RotateImg active={loadingRefresh} src={Refresh}/>
            <RefreshText onClick={getCreateOptions}>
              {t("REFRESH_LIST")}
            </RefreshText>
          </RefreshWrapper>
        </SelectRow>
        <div style={{display:'flex', marginTop:'10px',}}>
          <Button
            variant="primary"
            size="small"
            customStyles={{marginRight:"10px", padding:'9px'}}
            onClick={() => openModal('generate-ssh')}
            title={t("CREATE_NEW_KEY")}
          />
          <Button
            variant="stroke"
            size="small"
            onClick={() => openModal('create-ssh')}
            title={t("ADD_PUBLIC_SSH")}
          />
        </div>
      </SelectWrapper>
      <div style={{display:'flex', marginTop:'40px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleSubmit}
          title={t("CONTINUE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => openModal('unsaved')}
          title={t("CANCEL")}
        />
      </div>
      <ModalVariables
        modalType={modalType}
        closeModalCancel={closeModalCancel}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
      />
    </BodyForm>
  )
}

export default Configure
