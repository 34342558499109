import React, { FC, useEffect, useState } from "react";
import {
  Container,
  TitleBig,
  NavWrapper,
} from './styles'
import { useTranslation } from "react-i18next";
import { Tabs } from "../../components";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  Servers,
  ScriptsPage,
} from './components'
import { GpuContextProvider } from "../../context/gpuContext";

const GpuPage:FC = () => {
  const [t] = useTranslation()
  const [tabs, setTabs] = useState<any[]>([
    {
      title:t("SERVERS"),
      slug:'servers',
      id:'1'
    },
    {
      title:t("SCRIPTS"),
      slug:'scripts',
      id:'2'
    },
  ])
  const [activeTab, setActiveTab] = useState<string>()
  const location = useLocation()
  useEffect(() => {
    tabs.map((item:any) => {
      if(location.pathname.includes(item.slug)){
        setActiveTab(item.slug)
      }
    })
  },[location])
  return(
    <Container>
      <TitleBig>{t("GPU")}</TitleBig>
      <NavWrapper>
        <div>
          <Tabs
            tabs={tabs}
            size='display'
            location={activeTab}
            onChange={setActiveTab}
          />
        </div>
      </NavWrapper>
      <GpuContextProvider>
        <Routes>
          <Route path="/" element={<Navigate replace to="servers" />} /> 
          <Route path='/servers' element={<Servers />}/>
          <Route path='/scripts' element={<ScriptsPage />}/>
        </Routes>
      </GpuContextProvider>
    </Container>
  )
}

export default GpuPage;