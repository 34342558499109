import React, { FC, useEffect, useState } from 'react'
import {
  Container,
  TitleBig,
  DiagramWrapper,
  DiagramName,
  Description,
  Pie,
  PieBorder,
  GroupContainer,
  DescWrapper
} from './styles'
import { useAuth } from '../../context/user.context'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../components'
import { formatQuotasRam, formatQuotasStorage } from '../../utils/sizes'
type PieProp = {
  item:any
}

const Quotas:FC = () => {
  const {quotas} = useAuth()
  const [t] = useTranslation()
  const computing = [
    {
      title:t("INSTANCES"),
      desc:`${t("USED")} ${quotas?.core?.instances?.in_use} ${t("OF")} ${quotas?.core?.instances?.limit}`,
      id:'1',
      total:quotas?.core?.instances?.limit,
      used:quotas?.core?.instances?.in_use
    },
    {
      title:t("VCPUS"),
      desc:`${t("USED")} ${quotas?.core?.cores?.in_use} ${t("OF")} ${quotas?.core?.cores?.limit}`,
      id:'2',
      total:quotas?.core?.cores?.limit,
      used:quotas?.core?.cores?.in_use
    },
    {
      title:t("RAM"),
      desc:`${t("USED")} ${formatQuotasRam(quotas?.core?.ram?.in_use)} ${t("OF")} ${formatQuotasRam(quotas?.core?.ram?.limit)}`,
      id:'3',
      total:quotas?.core?.ram?.limit,
      used:quotas?.core?.ram?.in_use
    },
  ]
  const volumes = [
    {
      title:t("VOLUMES"),
      desc:`${t("USED")} ${quotas?.volume?.volumes?.in_use} ${t("OF")} ${quotas?.volume?.volumes?.limit}`,
      id:'5',
      total:quotas?.volume?.volumes?.limit,
      used:quotas?.volume?.volumes?.in_use
    },
    {
      title:t("STORAGE"),
      desc:`${t("USED")} ${formatQuotasStorage(quotas?.volume?.gigabytes?.in_use)} ${t("OF")} ${formatQuotasStorage(quotas?.volume?.gigabytes?.limit)}`,
      id:'4',
      total:quotas?.volume?.gigabytes?.limit,
      used:quotas?.volume?.gigabytes?.in_use
    },
    {
      title:t("SNAPSHOTS"),
      desc:`${t("USED")} ${quotas?.volume?.snapshots?.in_use} ${t("OF")} ${quotas?.volume?.snapshots?.limit}`,
      id:'1',
      total:quotas?.volume?.snapshots?.limit,
      used:quotas?.volume?.snapshots?.in_use
    },
    {
      title:t("BACKUPS"),
      desc:`${t("USED")} ${quotas?.volume?.backups?.in_use} ${t("OF")} ${quotas?.volume?.backups?.limit}`,
      id:'2',
      total:quotas?.volume?.backups?.limit,
      used:quotas?.volume?.backups?.in_use
    },
  ]
  const networks = [
    {
      title:t("FLOATING_IPS"),
      desc:`${t("USED")} ${quotas?.network?.floatingip?.used} ${t("OF")} ${quotas?.network?.floatingip?.limit}`,
      id:'1',
      total:quotas?.network?.floatingip?.limit,
      used:quotas?.network?.floatingip?.used
    },
    {
      title:t("SECURITY_GROUPS"),
      desc:`${t("USED")} ${quotas?.network?.security_group?.used} ${t("OF")} ${quotas?.network?.security_group?.limit}`,
      id:'2',
      total:quotas?.network?.security_group?.limit,
      used:quotas?.network?.security_group?.used
    },
    {
      title:t("RULES_OF_SECURITY_GROUPS"),
      desc:`${t("USED")} ${quotas?.network?.security_group_rule?.used} ${t("OF")} ${quotas?.network?.security_group_rule?.limit}`,
      id:'3',
      total:quotas?.network?.security_group_rule?.limit,
      used:quotas?.network?.security_group_rule?.used
    },
    {
      title:t("NETWORKS"),
      desc:`${t("USED")} ${quotas?.network?.network?.used} ${t("OF")} ${quotas?.network?.network?.limit}`,
      id:'4',
      total:quotas?.network?.network?.limit,
      used:quotas?.network?.network?.used
    },
    {
      title:t("PORTS"),
      desc:`${t("USED")} ${quotas?.network?.port?.used} ${t("OF")} ${quotas?.network?.port?.limit}`,
      id:'5',
      total:quotas?.network?.port?.limit,
      used:quotas?.network?.port?.used
    },
    {
      title:t("ROUTERS"),
      desc:`${t("USED")} ${quotas?.network?.router?.used} ${t("OF")} ${quotas?.network?.router?.limit}`,
      id:'6',
      total:quotas?.network?.router?.limit,
      used:quotas?.network?.router?.used
    },
  ]
  return(
    <Container>
      <TitleBig>{t("QUOTAS")}</TitleBig>
      <GroupContainer>
        <Typography variant='h3'>
          {t("COMPUTING_RESOURCES")}
        </Typography>
        {computing?.map((item:any) => (
          <PieComponent key={item.id} item={item}/>
        ))}
      </GroupContainer>
      <GroupContainer>
        <Typography variant='h3'>
          {t("VOLUMES")}
        </Typography>
        {volumes?.map((item:any) => (
          <PieComponent key={item.id} item={item}/>
        ))}
      </GroupContainer>
      <GroupContainer>
        <Typography variant='h3'>
          {t("NETWORK")}
        </Typography>
        {networks?.map((item:any) => (
          <PieComponent key={item.id} item={item}/>
        ))}
      </GroupContainer>
    </Container>
  )
}

export default Quotas


const PieComponent:FC<PieProp> = ({item}) => {
  const computed:number = +(item.used / item.total * 100).toFixed()
  const [animateComputed, setAnimateComputed] = useState<number>(0)
  useEffect(() => {
    const interval = setInterval(() => {
      if(animateComputed < computed){
        setAnimateComputed((prev:any) => prev + 1);
      } else if(animateComputed > computed) {
        setAnimateComputed((prev:any) => prev - 1);
      } else {
        clearInterval(interval);
      }
    },0);
    return () => clearInterval(interval);
  }, [animateComputed, item]);
  
  return (
    <DiagramWrapper  key={item.id}>
      <Pie used={animateComputed} >
        {computed > 0 && computed < 100 ? 
          <PieBorder transform={360 / 100 * animateComputed} ></PieBorder>
        : null}
      </Pie>
      <DescWrapper>
        <DiagramName data-test-id={item?.id === '1' ? 'instance-quota-container' : 'other'}>
          {item.title}
        </DiagramName>
        <Description>
          {item.desc}
        </Description>
      </DescWrapper>
    </DiagramWrapper>
  )
}