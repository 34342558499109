import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Button,
  TopProgress,
} from '../../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  InputsWrapper,
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { gpuService } from '../../../../api'
import { useUi } from '../../../../context/ui.context'


const CreateGpuScript:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const [initialValues, setInitialValues] = useState<any>({
    script:'',
    title:'',
  })
  const {scriptId} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [pageloading, setPageLoading] = useState<boolean>(scriptId ? true : false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [editedScript, setEditedScript] = useState<any>({})
  const onSubmit = (values:any) => {
    if(scriptId){
      setLoading(true)
      gpuService.editScript(values, scriptId)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          navigate(-1)
        },1000)
      })
      .catch((err:any) => {
        if(err.response?.data?.detail){
          viewAlert({
            severity:'error',
            message: err.response?.data?.detail
          })
        } else if(err?.response?.data) {
          setErrors(err.response.data)
        }
        setLoading(false)
      })
    } else {
      setLoading(true)
      gpuService.createScript(values)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          navigate(-1)
        },1000)
      })
      .catch((err:any) => {
        if(err.response?.data?.detail){
          viewAlert({
            severity:'error',
            message: err.response?.data?.detail
          })
        } else if(err?.response?.data) {
          setErrors(err.response.data)
        }
        setLoading(false)
      })
    }
  }
  const getSCriptById = async () => {
    if(!scriptId) return
    try {
      const res = await gpuService.getScriptsById(scriptId)
      await setEditedScript(res?.data)
      await setInitialValues({
        script: res?.data?.content,
        title: res?.data?.title,
      })
      await setPageLoading(false)
    } catch (err:any) {
      console.log('errgetscript', err);
    }
  }
  useEffect(() => {
    getSCriptById()
  },[scriptId])
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      script: yup.string().required(),
      // .max(255, `${t("STRING_VALIDATE_MAX")} 255 ${t("CHARACTERS")}`),
      title: yup.string().required(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  if(pageloading) return <TopProgress loading={pageloading}/>
  return(
    <CreateContainer>
      <Container>
        <BackArrow
          onClick={() => navigate('/gpu/scripts')}
          title={t("BACK_TO_SCRIPTS")}
        />
        <TitleBig>{t("CREATE_A_NEW_SCRIPT")}</TitleBig>
        <InputsWrapper>
          <Input
            fieldName='title'
            required
            title={t("TITLE")}
            placeholder=''
            {...formikProps}
          />
          <Input
            isTextArea
            required
            fieldName='script'
            title={t("SCRIPT")}
            customStyles={{minWidth:'550px', maxWidth:'550px', height:'200px'}}
            placeholder=''
            {...formikProps}
          />
        </InputsWrapper>
        <div style={{display:'flex', marginTop:'20px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={t("CREATE")}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/gpu/scripts")}
            title={t("CANCEL")}
          />
        </div>
      </Container>
    </CreateContainer>
  )
}

export default CreateGpuScript