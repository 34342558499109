import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../icons";
import { Button, ModalVariables, StartPage, TopProgress} from "../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from "../../context/ui.context";
import { floatingIpService } from "../../api";
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from './styles'
import { useAuth } from "../../context/user.context";
import { handleStatusName } from "../../utils/handle-status-name";
import rootConfig from "../../config";
import { compareArrays } from "../../utils/findIntersection";

const Gpus:FC = () => {
  const [ips, setIps] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)
  const {user, quotas} = useAuth()


  const [paginationData, setPaginationData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedGroup, setDeletedGroup] = useState<any>()
  const [editedIp, setEditedIp] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {
    setViewModalComplete,
    setActiveAnimationComplete,
    viewAlert
  } = useUi()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  } 
  useEffect(() => {
    if(compareArrays(ips, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()

  const _updateIps = () => {
    floatingIpService.getIps({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setIps(res.data.objects.map((ip:any) => {
        return{
          ...ip,
          // navigate:ip.id,
          name:ip.floating_ip_address,
          network:ip.floating_network?.name,
          associated:ip.associated_object?.name || ip.associated_object?.id,
          region:ip.floating_network.region,
          status: 
          (<StateWrapper>
            <GreenDot active={ip.status === "ACTIVE"}/>
            <Greentext active={ip.status === "ACTIVE"}>
              {handleStatusName(ip.status, t)}
            </Greentext>
          </StateWrapper>),
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      navigate('/home')
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    _updateIps()
    const interval = setInterval(() => {
      _updateIps()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[
    page,
    ordering,
    pageSize,
  ])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const _deleteGroups = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await ips.map((item:any) => {
          let promise = floatingIpService.deleteIp(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = floatingIpService.deleteIp(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (deletedGroup) {
        await floatingIpService.deleteIp(deletedGroup.id)
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      setPageLoading(false)
      setLoading(false)
    } finally {
      _updateIps()
      setViewModal(false)
      setChecked([])
      setAllChecked(false)
      setModalType('')
      setActiveAnimation(false)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedIp(null)
    setModalType('')
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("SET_PTR"),
      id:'2',
      icon:icons.Edit,
      onClick: (ip:any) => {
        openModal('set-ptr')
        setEditedIp(ip)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(ip:any) => {
        openModal('delete')
        setAlertTitle(t("FLOATING_IP"))
        setAlertSubTitle([ip])
        setDeletedGroup(ip)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("IP_ADDRESS"),
      key:'floating_ip_address'
    },
    {
      title:t("STATUS"),
      key:'status'
    },
    {
      title:t("NETWORK"),
      key:'network'
    },
    {
      title:t("ASSOCIATED_OBJECT"),
      key:'associated'
    },
    // {
    //   title:t("PLACEMENT_REGION"),
    //   key:'region'
    // },
    {
      title:'',
      key:'props'
    },
  ]
  
  
  const closeModal = (props?:any) => {
    if(modalType === 'delete'){
      _deleteGroups()
    } else if (modalType === 'create-floating-ip'){
      setLoading(true)
      floatingIpService.createFloatingIP(props)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          _updateIps()
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          setModalType('')
        },1000)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
      setModalType('')
    }
  }
  const _createFunc = () => {
    if(!user.user.active_client){
      navigate('/complete-registration')
    } else if(user.requires_email_verification){
      setViewModalComplete(true)
      setActiveAnimationComplete(true)
    } else if(quotas?.network?.floatingip?.limit === quotas?.network?.floatingip?.in_use){
      viewAlert({
        severity:'error',
        message:`${t('FLOATING_IP_QUOTA_EXCEEDED')}`,
      })
    } else {
      // navigate('create')
      openModal('create-floating-ip')
    }
  }

  return (
    <>
      <TopProgress loading={loading}/>
      <StartPage
        title={`${t("FLOATING_IPS")}`}
        noResourceText={`${t("YOU_HAVE_NO_FLOATING_IPS")}`}
        createResourceButton={`${t('CREATE_FLOATING_IP')}`}
        createResourceButtonEventOrLink={_createFunc}
        haveResource={ips?.length > 0}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_FLOATING_IP')}`}
        howCreateVideoText={`${t('HOW_CREATE_FLOATING_IP')}`}
        learnMoreAboutResourceLink={`${rootConfig.docs_url}category/floating-ips`}
        howCreateVideoUrl="https://www.youtube.com/embed/3ffI9KM4pbs"
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("FLOATING_IPS"))
                setAlertSubTitle(allChecked ? ips : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        tableData={{
          columns:Columns,
          data:ips,
          paginationData:paginationData,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateIps
        }}
        hideQuotas
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loading}
        loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedIp}
      />
    </>
  )
}


export default Gpus

