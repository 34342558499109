import styled from "styled-components";
import { BodyMedium } from "../../../../components/typography/styled";
import { statusColor } from "../../../../utils/statusColor";
type StatusProp = {
  active:any
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 30px;
  position: relative;
`

export const StateWrapper = styled.div`
  display: flex;
  gap:6px;
  align-items: center;
`

export const GreenDot = styled.div<StatusProp>`
  max-width:12px;
  max-height:12px;
  min-width:12px;
  min-height:12px;
  background-color: ${({active}) => statusColor(active)};
  border-radius: 100%;
`

export const Greentext = styled(BodyMedium)<StatusProp>`
  color: ${({active}) => statusColor(active)};
`