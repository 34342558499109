import React, { FC, useState, useEffect } from 'react'
import {
  Container,
  LogoImg,
  TitleDivider,
  ArrowCloseContainer,
  ArrowCloseImg,
} from './styles'
import { useTheme } from 'styled-components'
import { useAuth } from '../../context/user.context'

import HomeIcon from './icons/home-default.svg'
import HomeHoverIcon from './icons/home-hover.svg'
import InstanceIcon from './icons/instances-default.svg'
import InstanceHoverIcon from './icons/instances-hover.svg'
import VolumesIcon from './icons/volumes-default.svg'
import VolumesHoverIcon from './icons/volumes-hover.svg'
import ImagesIcon from './icons/images-default.svg'
import ImagesHoverIcon from './icons/images-hover.svg'
import SSHKeysIcon from './icons/ssh-keys-default.svg'
import SSHKeysHoverIcon from './icons/ssh-keys-hover.svg'
import NetworksIcon from './icons/network-default.svg'
import NetworksHoverIcon from './icons/network-hover.svg'
import KubernetesIcon from './icons/kubernetes-default.svg'
import KubernetesHoverIcon from './icons/kubernetes-hover.svg'
import SecurityIcon from './icons/security-group-default.svg'
import SecurityHoverIcon from './icons/security-group-hover.svg'
import BalancersIcon from './icons/load-balancer-default.svg'
import BalancersHoverIcon from './icons/load-balancer-hover.svg'
import FloatingIcon from './icons/floating-ip-default.svg'
import FloatingHoverIcon from './icons/floating-ip-hover.svg'
import BillingIcon from './icons/billing-default.svg'
import BillingHoverIcon from './icons/billing-hover.svg'
import SupportIcon from './icons/support-default.svg'
import SupportHoverIcon from './icons/support-hover.svg'
import RouterIcon from './icons/router-default.svg'
import RouterHoverIcon from './icons/router-hover.svg'
import PortIcon from './icons/port-default.svg'
import PortHoverIcon from './icons/port-hover.svg'
import ObjStoreIcon from './icons/obj-store-ic.svg'
import ObjStoreHoverIcon from './icons/obj-store-hover-ic.svg'
import { useTranslation } from 'react-i18next'
import SideBarTab from '../side-bar-item'
import ArrowClose from './icons/arrowClose.svg'
import { useUi } from '../../context/ui.context'
import { useLocation, useNavigate } from 'react-router'
import LogoSmall from './logo-cube.svg'
import LogoSmallDark from './logo-cube.svg'
import LogoDark from './logo-white.svg'
import Logo from './logo-black.svg'
import OperationsHoverIcon from './icons/operations-hover.svg'
import OperationsIcon from './icons/operations-default.svg'
import AffiliateIcon from './icons/affiliate-default.svg'
import BareMetalHoverIcon from './icons/bare-metal-hover.svg'
import BareMetalIcon from './icons/bare-metal.svg'
import {
  authService,
  floatingIpService,
  imageService,
  instanceService,
  loadBalancerService,
  networkService,
  routerService,
  securityService,
  volumeService,
} from '../../api'
import TopProgress from '../TopBarProgress'
// import {
//   AffiliateIcon,
//   ArrowClose,
//   BareMetalIcon,
// } from './icons'
import styles from '../../styles/icons.module.css'

const SideBar:FC = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {appMenu, user} = useAuth()
  const {setSmallBar, smallBar, viewAlert} = useUi()
  const [loadingNav, setLoadingNav] = useState<boolean>(false)
  const [activeText, setACtiveText] = useState<boolean>(false)
  useEffect(() => {
    if(!smallBar) {
      setTimeout(() => {
        setACtiveText(true)
      },150)
    } else {
      setACtiveText(false)
    }
  },[smallBar])
  const changeSizeBar = () => {
    setSmallBar(!smallBar)
  }
  const theme:any = useTheme()
  const location = useLocation()
  const checkMenu = async (url:string) => {
    switch (url) {
      case 'instances':
        return await instanceService.getInstanceList({
            page:1,
            ordering:'',
            pageSize:10
          })
      case 'volumes':
        return await volumeService.getVolumeList({
            page:1,
            ordering:'',
            pageSize:10
          })
      case 'images':
        return await imageService.getImagesList({
          page:1,
          ordering:'',
          pageSize:10
        })
      case 'networks':
        return await networkService.getAllNetworks({
          page:1,
          ordering:'',
          pageSize:10
        })
      case 'routers':
        return await routerService.getRouters({
          page:1,
          ordering:'',
          pageSize:10
        })
      case 'security-groups':
        return await securityService.getSecurityList({
          page:1,
          ordering:'',
          pageSize:10
        })
      case 'floating-ips':
        return await floatingIpService.getIps({
          page:1,
          ordering:'',
          pageSize:10
        })
      case 'load-balancers':
        return await loadBalancerService.getList({
          page:1,
          ordering:'',
          pageSize:10
        })
      default:
        return await authService.getUser()
    }
  }
  const checkClient = async (route:string, navigateTo:string) => {
    setLoadingNav(true)
    if(!user.user.active_client){
      setLoadingNav(false)
      navigate('/complete-registration')
      return
    }
    try {
      await checkMenu(navigateTo?.replace('/', ''))
      await navigate(navigateTo)
    } catch (err:any) {
      viewAlert({
        severity:'error',
        message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      return false
      // navigate('/home')
    } finally {
      setLoadingNav(false)
    }
  }
  return(
    <Container
      data-test-id='sidebar-container'
      active={smallBar}
    >
      <TopProgress loading={loadingNav}/>
      <ArrowCloseContainer
        active={smallBar}
        onClick={changeSizeBar}
      >
        <ArrowCloseImg
          active={smallBar}
          src={ArrowClose}
        />
        {/* <ArrowCloseImg active={smallBar}>
          <ArrowClose styles={`${styles.iconArrow} ${smallBar ? styles.iconArrowArrow : ''}`}/>

        </ArrowCloseImg> */}
      </ArrowCloseContainer>
      {/* <LogoImg src={Logo}/> */}
      <LogoImg onClick={() => {
        window.open('https://onecloudplanet.com/')
        // navigate('/home')
      }} active={!activeText}
        src={
          theme.isDark ? 
            !activeText ? LogoSmallDark : LogoDark
          :
            !activeText ? LogoSmall : Logo
        }
      />
          <SideBarTab
            href={'/home'}
            isFirst
            isSmall={smallBar}
            active={location.pathname?.includes('/home')}
            defaultIcon={HomeIcon}
            hoverIcon={HomeHoverIcon}
            title={t("HOME")}
            activeText={activeText}
            onClick={() => navigate('/home')}
          />

          <TitleDivider
            active={!activeText}
          >
            {!activeText ? '_'
            : t("CLOUD_COMPUTE")}
          </TitleDivider>
          <SideBarTab
            href='/instances'
            isSmall={smallBar}
            active={location.pathname?.includes('/instances')}
            defaultIcon={InstanceIcon}
            hoverIcon={InstanceHoverIcon}
            title={t("INSTANCES")}
            activeText={activeText}
            onClick={() => checkClient('openstack/instances', '/instances')}
          />
        
        {appMenu?.includes("openstack.instances") &&
          <SideBarTab
            href='/gpu'
            isSmall={smallBar}
            active={location.pathname?.includes('/gpu')}
            defaultIcon={InstanceIcon}
            hoverIcon={InstanceHoverIcon}
            title={t("GPU")}
            activeText={activeText}
            onClick={() => checkClient('openstack/instances', '/instances')}
          />
        }
        {appMenu?.includes("openstack.volumes") &&
          <>
            <SideBarTab
              href={'/volumes'}
              isSmall={smallBar}
              active={location.pathname?.includes('/volumes')}
              defaultIcon={VolumesIcon}
              hoverIcon={VolumesHoverIcon}
              // dropOptions={true}
              title={t("VOLUMES")}
              // onClick={() => navigate('/volumes')}
              onClick={() => checkClient('openstack/volumes', '/volumes')}
              activeText={activeText}
            />
            <SideBarTab
              href={'/volume-backups'}
              isSmall={smallBar}
              active={location.pathname?.includes('/volume-backups')}
              defaultIcon={VolumesIcon}
              hoverIcon={VolumesHoverIcon}
              // dropOptions={true}
              title={t("VOLUME_BACKUPS")}
              // onClick={() => navigate('/volumes')}
              onClick={() => checkClient('openstack/volume-backups', '/volume-backups')}
              activeText={activeText}
            />
            <SideBarTab
              href={'/images'}
              isSmall={smallBar}
              active={location.pathname?.includes('/images')}
              defaultIcon={ImagesIcon}
              hoverIcon={ImagesHoverIcon}
              title={t("IMAGES")}
              // onClick={() => navigate('/images')}
              onClick={() => checkClient('openstack/images', '/images')}
              activeText={activeText}
            />
          </>
        }
        {localStorage.getItem('region_ocp_zone') === 'pl-1' ? null :
          appMenu?.includes("openstack.images") &&
          <SideBarTab
            href={'/object-storage'}
            isSmall={smallBar}
            active={location.pathname?.includes('/object-storage')}
            defaultIcon={ObjStoreIcon}
            hoverIcon={ObjStoreHoverIcon}
            title={t("OBJECT_STORAGE")}
            // onClick={() => navigate('/images')}
            onClick={() => checkClient('openstack/images', '/images')}
            activeText={activeText}
          />
        }
        {appMenu?.includes("openstack.coe.clusters") && 
          <TitleDivider
            active={!activeText}
          >
            {!activeText ? '_'
            : t("CONTAINERS")}
          </TitleDivider>
          }
          <SideBarTab
            href={'/kubernetes'}
            isSmall={smallBar}
            active={location.pathname?.includes('/kubernetes')}
            onClick={() => checkClient('/kubernetes', '/kubernetes')}
            defaultIcon={KubernetesIcon}
            hoverIcon={KubernetesHoverIcon}
            title={t("KUBERNETES_CLUSTERS")}
            // isSoon={!user?.user?.access_kubernetes}
            isBeta={true}
            activeText={activeText}
          />
        {localStorage.getItem('region_ocp_zone') === 'pl-1' ? null :
          <TitleDivider
            style={{marginTop:'10px'}}
            active={!activeText}
          >
            {!activeText ? '_'
            : 
            null
            // t("CONTAINERS")
            }
          </TitleDivider>
        }
        {localStorage.getItem('region_ocp_zone') === 'pl-1' ? null :
            <SideBarTab
              href={'/bare-metal'}
              isSmall={smallBar}
              active={location.pathname?.includes('/bare-metal')}
              onClick={() => checkClient('/bare-metal', '/bare-metal')}
              defaultIcon={BareMetalIcon}
              hoverIcon={BareMetalHoverIcon}
              title={t("BARE_METAL")}
              // isSoon={!user?.user?.access_kubernetes}
              // isBeta={true}
              activeText={activeText}
            />
        }
        {
          (appMenu?.includes("openstack.networks") ||
          appMenu?.includes("openstack.ports") ||
          appMenu?.includes("openstack.routers") ||
          appMenu?.includes("openstack.securitygroups") ||
          appMenu?.includes("openstack.lbaas") ||
          appMenu?.includes("openstack.floatingips")) &&
          <TitleDivider
            active={!activeText}
          >
            {!activeText ? '_'
            : t("NETWORKS")}
          </TitleDivider>
        }
          <SideBarTab
            href={'/networks'}
            isSmall={smallBar}
            active={location.pathname?.includes('/networks')}
            defaultIcon={NetworksIcon}
            hoverIcon={NetworksHoverIcon}
            // onClick={() => navigate('/networks')}
            onClick={() => checkClient('openstack/networks', '/networks')}
            title={t("NETWORKS")}
            activeText={activeText}
          />
        {/* <SideBarTab
          isSmall={smallBar}
          active={false}
          defaultIcon={HomeIcon}
          hoverIcon={HomeHoverIcon}
          title={t("SUBNET_POOLS")}
          activeText={activeText}
        /> */}
          <SideBarTab
            href={'/ports'}
            isSmall={smallBar}
            active={location.pathname?.includes('/ports')}
            defaultIcon={PortIcon}
            hoverIcon={PortHoverIcon}
            title={t("PORTS")}
            onClick={() => navigate('/ports')}
            activeText={activeText}
          />
          <SideBarTab
            href={'/routers'}
            isSmall={smallBar}
            active={location.pathname?.includes('/routers')}
            defaultIcon={RouterIcon}
            // onClick={() => navigate('/routers')}
            onClick={() => checkClient('openstack/routers', '/routers')}
            hoverIcon={RouterHoverIcon}
            title={t("ROUTERS")}
            activeText={activeText}
          />
          <SideBarTab
            href={'/security-groups'}
            isSmall={smallBar}
            active={location.pathname?.includes('/security-groups') && !location.pathname?.includes('/instances')}
            defaultIcon={SecurityIcon}
            // onClick={() => navigate('/security-groups')}
            onClick={() => checkClient('openstack/security-groups', '/security-groups')}
            hoverIcon={SecurityHoverIcon}
            title={t("SECURITY_GROUPS")}
            activeText={activeText}
          />
          <SideBarTab
            href={'/load-balancers'}
            isSmall={smallBar}
            active={location.pathname?.includes('/load-balancers')}
            defaultIcon={BalancersIcon}
            onClick={() => checkClient('openstack/load-balancers', '/load-balancers')}
            // isSoon
            hoverIcon={BalancersHoverIcon}
            title={t("LOAD_BALANCERS")}
            activeText={activeText}
          />
          <SideBarTab
            href={'/floating-ips'}
            isSmall={smallBar}
            active={location.pathname?.includes('/floating-ips')}
            defaultIcon={FloatingIcon}
            hoverIcon={FloatingHoverIcon}
            // onClick={() => navigate('/floating-ips')}
            onClick={() => checkClient('openstack/floating-ips', '/floating-ips')}
            title={t("FLOATING_IPS")}
            activeText={activeText}
          />
        <TitleDivider active={!activeText}>
          <div style={!activeText ? {} : {display:'none'}}>
          _
          </div>
        </TitleDivider>
          <SideBarTab
            href={'/billing'}
            isSmall={smallBar}
            active={location.pathname?.includes('/billing')}
            defaultIcon={BillingIcon}
            onClick={() => checkClient('/billing', '/billing')}
            // onClick={() => navigate('/billing')}
            hoverIcon={BillingHoverIcon}
            // dropOptions={true}
            title={t("BILLING")}
            activeText={activeText}
            isDown={true}
          />
          <SideBarTab
            href={'/ssh-keys'}
            isSmall={smallBar}
            active={location.pathname?.includes('/ssh-keys')}
            defaultIcon={SSHKeysIcon}
            hoverIcon={SSHKeysHoverIcon}
            onClick={() => checkClient('/ssh-keys', '/ssh-keys')}
            title={t("SSH_KEYS")}
            activeText={activeText}
          />
          <SideBarTab
            href={'/affiliate'}
            isSmall={smallBar}
            active={location.pathname?.includes('/affiliate')}
            onClick={() => navigate('/affiliate')}
            defaultIcon={AffiliateIcon}
            hoverIcon={AffiliateIcon}
            title={t("AFFILIATE")}
            activeText={activeText}
            viewIfNoClient={true}
          />
        <SideBarTab
          href={'/support'}
          isSmall={smallBar}
          active={location.pathname?.includes('/support')}
          onClick={() => navigate('/support')}
          defaultIcon={SupportIcon}
          hoverIcon={SupportHoverIcon}
          title={t("SUPPORT")}
          activeText={activeText}
          viewIfNoClient={true}
        />
          <SideBarTab
            href={'/operations'}
            isSmall={smallBar}
            active={location.pathname?.includes('/operations')}
            onClick={() => navigate('/operations')}
            defaultIcon={OperationsIcon}
            hoverIcon={OperationsHoverIcon}
            title={t("OPERATIONS")}
            activeText={activeText}
          />
    </Container>
  )
}

export default SideBar
