import React, { createContext, useEffect, useState, FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { gpuService, instanceService, securityService } from '../api';
import { handleOsIcons } from '../hooks/useOsIcons';
import { formatCurrency, formatCurrencyString } from '../utils/display-price';
import { useAuth } from './user.context';
import { useTranslation } from 'react-i18next';
import { formatQuotasRam } from '../utils/sizes';
export const GpuContext = createContext<GpuContextProps>({} as GpuContextProps);

interface GpuContextProps {
  pageLoading:boolean;
  setPageLoading:(type:boolean) => void;
  configureData:any;
  setConfigureData:(data:any) => void;
  getCreateOptions:() => void;
  plans:any[];
  scripts:any[];
  loadingRefresh:boolean;
}

type ContextProps = {
  children?:any
}

const GpuContextProvider: React.FC<ContextProps> = (props) => {
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [configureData, setConfigureData] = useState<any>()
  const [plans, setPlans] = useState<any[]>([])
  const [scripts, setScripts] = useState<any[]>([])
  const {user} = useAuth()
  const [t] = useTranslation()
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false)
  const location = useLocation()

  const getCreateOptions = async () => {
    try{
      await setLoadingRefresh(true)
      const res = await gpuService.getCreateOptions()
      const resScripts = await gpuService.getScripts()
      await setScripts(resScripts?.data?.map((script:any) => {
        return{
          // id: script.id,
          value: `${script.id}`,
          label: script.title,
        }
      }))
      await setPlans(res?.data?.map((plan:any) => {
        return{
          ...plan,
          id:plan?.name,
          value:plan?.name,
          label: plan?.gpu_label,
          type:"flavor",
          options: [`${plan?.gpu} GPU`, `${plan?.cpu} CPU`, `${formatQuotasRam(plan?.ram)} RAM`, `${formatCurrency((+plan?.hourly_price).toFixed(2), user.user.clients?.[0]?.currency || 'UAH')}`],
          os_availables: plan?.os_availables?.filter((os:any) => os?.active_flag)?.map((os:any) => {
            return{
              ...os,
              id: os.name,
              value: os.name,
              label: os.description,
              icon: handleOsIcons(os.so_base?.toLowerCase()),
            }
          }),
        }
      }))
      await setPageLoading(false)
    } catch (err:any) {
      setPageLoading(false)
      setLoadingRefresh(false)
      console.log(err)
    }

  }
  useEffect(() => {
    getCreateOptions()
  },[])
  return (
    <GpuContext.Provider 
      value={{
        pageLoading,
        setPageLoading,
        configureData,
        setConfigureData,
        getCreateOptions,
        plans,
        scripts,
        loadingRefresh,
      }}
      {...props}
    />
  );
};

const useGpu = () => {
  const context = React.useContext(GpuContext);
  if (context === undefined) {
    throw new Error('useGpu must be used within a GpuProvider');
  }
  return context as GpuContextProps;
};

export { GpuContextProvider, useGpu };



