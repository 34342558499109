import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const gpuService = {
  getServers:(queries:QueriesApi) => ApiClient.get(`gpu_servers/?page_size=${queries.pageSize}&ordering=${queries.ordering}&page=${queries.page}`),
  getServerById:(id:any) => ApiClient.get(`gpu_servers/${id}/`),
  getCreateOptions:() => ApiClient.get('gpu_servers/available_plans/'),
  getScripts:() => ApiClient.get('gpu-servers-scripts/'),
  getScriptsById:(id:any) => ApiClient.get(`gpu-servers-scripts/${id}/`),
  deleteScriptsById:(id:any) => ApiClient.delete(`gpu-servers-scripts/${id}/`),
  createScript:(data:any) => ApiClient.post('gpu-servers-scripts/', data),
  createServer:(data:any) => ApiClient.post('gpu_servers/', data),
  editServer:(data:any, id:any) => ApiClient.patch(`gpu_servers/${id}/`, data),
  editScript:(data:any, id:any) => ApiClient.put(`gpu-servers-scripts/${id}/`, data),
  deleteServerById:(id:any) => ApiClient.delete(`gpu_servers/${id}/`)
}

export default gpuService