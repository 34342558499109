import styled from "styled-components";
import { HeadLine1 } from "../../../../components/typography/styled";

export const Container = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: column;
`
export const CreateContainer = styled.div`
  justify-content: space-between;
  display: inline-flex;
  align-items: flex-start;
  padding: 10px 30px 50px 30px;
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-top: 20px;
  margin-bottom: 20px;
`

export const InputsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 390px;
  gap:20px;
  margin-bottom:20px;
`

