import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../icons";
import { Button, ClipBoardWrapper, ModalVariables, StartPage, TopProgress} from "../../../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { useUi } from "../../../../context/ui.context";
import { floatingIpService, gpuService } from "../../../../api";
import {
  StateWrapper,
  GreenDot,
  Greentext,
} from './styles'
import { handleStatusName } from "../../../../utils/handle-status-name";
import { compareArrays } from "../../../../utils/findIntersection";

const GpuServers:FC = () => {
  const [ips, setIps] = useState<any[]>([])
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', NumberParam)

  const [paginationData, setPaginationData] = useState<any>()
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedGroup, setDeletedGroup] = useState<any>()
  const [editedIp, setEditedIp] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {
    viewAlert
  } = useUi()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  }
  useEffect(() => {
    if(compareArrays(ips, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()

  const _updateIps = () => {
    gpuService.getServers({
      page:page||1,
      ordering,
      pageSize:pageSize||10
    })
    .then((res:any) => {
      setPaginationData(res.data)
      setIps(res.data.objects.map((server:any) => {
        return{
          ...server,
          navigate:`${server.id}`,
          name:server.label,
          type:server.gpu_label,
          os:server.so_label,
          username:(
            <ClipBoardWrapper>
            ecuser
            </ClipBoardWrapper>
          ),
          access_ip: (
            <ClipBoardWrapper>
              {server?.ipv4}
            </ClipBoardWrapper>
          ),
          status: 
          (<StateWrapper>
            <GreenDot active={server?.status}/>
            <Greentext active={server?.status}>
              {handleStatusName(server.status, t)}
            </Greentext>
          </StateWrapper>),
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      // navigate('/home')
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    _updateIps()
    const interval = setInterval(() => {
      _updateIps()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[
    page,
    ordering,
    pageSize,
  ])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const _deleteGroups = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await ips.map((item:any) => {
          let promise = gpuService.deleteServerById(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = gpuService.deleteServerById(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (deletedGroup) {
        await gpuService.deleteServerById(deletedGroup.id)
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      setPageLoading(false)
      setLoading(false)
    } finally {
      _updateIps()
      setViewModal(false)
      setChecked([])
      setAllChecked(false)
      setModalType('')
      setActiveAnimation(false)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedIp(null)
    setModalType('')
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("RENAME"),
      id:'2',
      icon:icons.Edit,
      onClick: (server:any) => {
        openModal('rename')
        setEditedIp(server)
        setAlertTitle(`${t("RENAME_INSTANCE")} «${server.name}»`)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(server:any) => {
        openModal('delete')
        setAlertTitle(t("GPU_SERVER"))
        setAlertSubTitle([server])
        setDeletedGroup(server)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:t("INSTANCE_STATE"),
      key:'status',
    },
    {
      title:t("INSTANCE_TYPE"),
      key:'type'
    },
    {
      title:t("USERNAME"),
      key:'username'
    },
    {
      title:t("ACCESS_IP"),
      key:'access_ip'
    },
    {
      title:t("OS_SHORT"),
      key:'os'
    },
    {
      title:'',
      key:'props'
    },
  ]
  
  
  const closeModal = (props?:any) => {
    if(modalType === 'delete'){
      _deleteGroups()
    } else if (modalType === 'rename'){
      setLoading(true)
      gpuService.editServer({label:props?.name}, editedIp?.id)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          _updateIps()
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          setModalType('')
        },1000)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
      setModalType('')
    }
  }

  return (
    <>
      <TopProgress loading={loading}/>
      {/* @ts-ignore */}
      <StartPage
        title={`${t("GPU_SERVERS")}`}
        noResourceText={`${t("YOU_HAVE_NO_GPU_SERVERS")}`}
        createResourceButton={`${t('CREATE_GPU_SERVER')}`}
        createResourceButtonEventOrLink={() => navigate('create')}
        haveResource={ips?.length > 0}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_GPU_SERVERS')}`}
        howCreateVideoText={`${t('HOW_CREATE_GPU_SERVER')}`}
        // learnMoreAboutResourceLink={`${rootConfig.docs_url}category/floating-ips`}
        // howCreateVideoUrl="https://www.youtube.com/embed/3ffI9KM4pbs"
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("GPU_SERVERS"))
                setAlertSubTitle(allChecked ? ips : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        tableData={{
          columns:Columns,
          data:ips,
          paginationData:paginationData,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateIps
        }}
        hideQuotas
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        instance={editedIp}
        alertTitle={alertTitle}
        loading={loading}
        loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedIp}
      />
    </>
  )
}


export default GpuServers

