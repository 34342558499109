import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  TopInfoWrapper,
  NetworkBlock,
  NetworkText,
  NetworkBorder,
  NetworkLine,
  InfoName,
  InfoNameGrey,
  InfoNameBold,
  NameBorder,
  NetworkContainer,
  IpGroupsWrapper,
  NetworkInfoContainer,
} from './styles'
import { InfoBlock, Table, Button, ModalVariables } from '../../../../../components'
import { InfoTitle, InfoContainer } from '../../../../../components/info-block/styles'
import { icons } from '../../../../../icons'
import MetricsChart from '../../../../../components/metrics'
import { timeConverterMMDDYYHHMM } from '../../../../../utils/dates'
import { findIntersection } from '../../../../../utils/findIntersection'
import { instanceService } from '../../../../../api'
import { useUi } from '../../../../../context/ui.context'
import { BodyDefault } from '../../../../../components/typography/styled'
import { handleOsIcons } from '../../../../../hooks/useOsIcons'
import { useNavigate } from 'react-router-dom'
import { PropContainer, Prop } from '../../../styles'
import { formatQuotasRam } from '../../../../../utils/sizes'
// import {
//   datesToPercentagesWithValues,
//   calculateScales,
//   createPercentageArrays
// } from '../metrics/metricFuncs'
type OverviewProps = {
  instance:any,
}


const Overview:FC<OverviewProps> = ({instance}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [attachVolumeLoading, setAttachVolumeLoading] = useState<boolean>(false)
  const [alertTitle, setAlertTitle] = useState<any>()
  const [editedIp, setEditedIp] = useState<any>()


  const {viewAlert} = useUi()
  console.log('instance', instance);
  const specifications = [
    {
      title:t("IMAGE"),
      id:'1',
      value:instance?.so_label,
      icon: handleOsIcons(instance?.so)
    },
    {
      title:t("FLAVOR"),
      id:'2',
      value:instance?.host_type
    },
    {
      title:t("GPU_LABEL"),
      id:'2',
      value:instance?.gpu_label
    },
    {
      title:t("MEMORY"),
      id:'3',
      left:true,
      options:['2'],
      custom: (
        <NameBorder style={{border:'none', padding:'0px'}}>
          <InfoNameBold>{formatQuotasRam(instance?.ram)}</InfoNameBold>
          &nbsp;
          <InfoName>RAM</InfoName>
        </NameBorder>
      ),
      // value:'2GB (2048) RAM'
    },
    {
      title:t("VIRTUAL_CPU_S"),
      id:'4',
      value:instance?.core
    },
    {
      title:t("VIRTUAL_GPU_S"),
      id:'4',
      value:instance?.gpu
    },

  ]
  const other = [
    {
      title:t("INSTANCE_UUID"),
      id:'1',
      value:instance.name,
      copy:true
    },
    {
      title:t("CREATED_AT"),
      id:'2',
      value: timeConverterMMDDYYHHMM(instance.created_dt) 
    },
    // {
    //   title:t("REGION"),
    //   id:'3',
    //   value:instance.region
    // },
    // {
    //   title:t("TOTAL_TRAFFIC_CYCLE"),
    //   id:'4',
    //   value:`${instance.current_cycle_traffic} GB`
    // },
    // {
    //   title:t("TOTAL_TRAFFIC_MONTH"),
    //   id:'5',
    //   value:`${instance.current_month_traffic} GB`
    // },
    // {
    //   title:t("TAGS"),
    //   id:'6',
    //   custom: (
    //     <div style={{display:'flex', gap:'6px'}}>
    //     {instance?.tags?.map((tag:any) => (
    //       <PropContainer key={tag?.id}>
    //         <Prop>
    //           {tag?.name}
    //         </Prop>
    //       </PropContainer>
    //     ))}
    //   </div>
    //   ),
    // },
  ]
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const closeModal = (values:any) => {
    let data: any;
    let serviceCall: any;
    let successMessage: any;
    let errorMessage: string = t("ERROR_OCCURRED");

    switch (modalType) {
      case 'attach-volume':
        data = { volume_id: values.volume };
        serviceCall = () => instanceService.attachVolume(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      case 'assign-ip':
        data = values;
        serviceCall = () => instanceService.addFloatingIp(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      case 'detach-volume':
        data = { volume_id: values };
        serviceCall = () => instanceService.detachVolume(instance.id, data);
        successMessage = (res: any) => res.data?.detail;
        break;

      default:
        setViewModal(false);
        setActiveAnimation(false);
        setModalType('')
        return; // если modalType не соответствует ни одному из известных значений, ничего не делаем
    }

    setAttachVolumeLoading(true);

    serviceCall()
    .then((res: any) => {
      viewAlert({
        severity: 'info',
        message: successMessage(res),
      });
      setViewModal(false);
      setActiveAnimation(false);
      setModalType('')
    })
    .catch((err: any) => {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || errorMessage,
      });
    })
    .finally(() => {
      setAttachVolumeLoading(false);
    });
  }
  const items = [
    {
      title:t("DETACH"),
      id:'1',
      icon:icons.MinusDefault,
      onClick:(volume:any) => {
        openModal('detach-volume')
        setAlertTitle(volume.volume_id)
      },
      divider:false
    },
  ]
  const Columns = [
    {
      title:t('ID'),
      key:'name'
    },
    // {
    //   title:t("STATUS"),
    //   key:'status'
    // },
    {
      title:t("SIZE"),
      key:'size'
    },
    {
      title:t("HAS_SNAPSHOT"),
      key:'has_snapshots'
    },
    {
      title:'',
      key:'props'
    }
  ]
  return(
    <Container>
      <TopInfoWrapper>
        <InfoBlock
          data={specifications}
          title={t("INSTANCE_SPECIFICATIONS")}
          customStyles={{width:"545px", flex:'auto 1', marginBottom:'0px'}}
        />
        <InfoBlock
          data={other}
          title={t("OTHER_DETAILS")}
          customStyles={{width:"545px", flex:'auto 1', marginBottom:'0px'}}
        />
      </TopInfoWrapper>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instanceId={instance.id}
        loading={attachVolumeLoading}
        dataToEdit={modalType === 'set-ptr' ? editedIp : instance}
      />
    </Container>
  )
}

export default Overview

