import React, {FC, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../../../icons";
import { Button, ModalVariables, StartPage, TopProgress} from "../../../../components";
import Illustration from './illustration.svg'
import IllustrationDark from './illustration-dark.svg'
import { useNavigate } from "react-router";
import { useUi } from "../../../../context/ui.context";
import { floatingIpService, gpuService } from "../../../../api";
import { compareArrays } from "../../../../utils/findIntersection";

const GpuServers:FC = () => {
  const [ips, setIps] = useState<any[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [deletedGroup, setDeletedGroup] = useState<any>()
  const [editedIp, setEditedIp] = useState<any>()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [checked, setChecked] = useState<any[]>([])
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {
    viewAlert
  } = useUi()
  const editChecked = (id:any, isAll?:boolean) => {
    if(id === null) {
      setChecked([])
      return
    }
    const founded = checked.find((item:any) => item.id === id?.id)
    if(founded) {
      if(isAll){ 
        return 
      } else {
        setChecked((prev:any) => {
          return prev.filter((item:any) => item.id !== id?.id)
        })
        setAllChecked(false)
      }
    } else {
      setChecked((prev) => [...prev, id])
    }
  }
  useEffect(() => {
    if(compareArrays(ips, checked) && checked?.length !== 0){
      setAllChecked(true)
    }
  },[checked])
  const [t] = useTranslation()
  const navigate = useNavigate()

  const _updateIps = () => {
    gpuService.getScripts()
    .then((res:any) => {
      setIps(res.data.map((script:any) => {
        return{
          ...script,
          name:script.title,
        }
      }))
    })
    .catch((e) => {
      viewAlert({
        severity:'error',
        message:e?.response?.data?.detail || `${t("ERROR_OCCURRED")}`,
      })
      // navigate('/home')
    })
    .finally(() => {
      setPageLoading(false)
      setLoading(false)
    })
  }
  useEffect(() => {
    _updateIps()
    const interval = setInterval(() => {
      _updateIps()
    },10000)
    return () => {
      clearInterval(interval)
    }
  },[])
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const _deleteGroups = async () => {
    setLoading(true)
    try{
      if(allChecked) {
        let promises:any[] = []
        await ips.map((item:any) => {
          let promise = gpuService.deleteScriptsById(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (checked?.length > 0){
        let promises:any[] = []
        await checked.map((item:any) => {
          let promise = gpuService.deleteScriptsById(item.id)
          promises.push(promise)
        })
        await Promise.all(promises)
      } else if (deletedGroup) {
        await gpuService.deleteScriptsById(deletedGroup.id)
      }
    } catch (e:any) {
      if(e?.response?.data.detail){
        viewAlert({
          severity:'error',
          message:e.response.data.detail,
        })
      }
      setPageLoading(false)
      setLoading(false)
    } finally {
      _updateIps()
      setViewModal(false)
      setChecked([])
      setAllChecked(false)
      setModalType('')
      setActiveAnimation(false)
    }
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setEditedIp(null)
    setModalType('')
    setActiveAnimation(false)
  }
  const items = [
    {
      title:t("EDIT"),
      id:'2',
      icon:icons.Edit,
      onClick: (script:any) => {
        navigate(`/gpu/scripts/edit/${script?.id}`)
        setEditedIp(script)
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'4',
      onClick:(script:any) => {
        openModal('delete')
        setAlertTitle(t("GPU_SCRIPT"))
        setAlertSubTitle([script])
        setDeletedGroup(script)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const Columns = [
    {
      title:t("NAME"),
      key:'name',
    },
    {
      title:'',
      key:'props'
    },
  ]
  
  
  const closeModal = (props?:any) => {
    if(modalType === 'delete'){
      _deleteGroups()
    } else if (modalType === 'create-floating-ip'){
      setLoading(true)
      floatingIpService.createFloatingIP(props)
      .then((res:any) => {
        setLoadingComplete(true)
        setTimeout(() => {
          _updateIps()
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          setModalType('')
        },1000)
      })
      .catch((err) => {
        viewAlert({
          severity:'error',
          message: err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
        })
        setLoading(false)
      })
    } else {
      setViewModal(false)
      setActiveAnimation(false)
      setModalType('')
    }
  }

  return (
    <>
      <TopProgress loading={loading}/>
      {/* @ts-ignore */}
      <StartPage
        title={`${t("GPU_SCRIPTS")}`}
        noResourceText={`${t("YOU_HAVE_NO_GPU_SCRIPTS")}`}
        createResourceButton={`${t('CREATE_GPU_SCRIPT')}`}
        createResourceButtonEventOrLink={() => navigate('/gpu/scripts/create')}
        haveResource={ips?.length > 0}
        pageLoading={pageLoading}
        learnMoreAboutResource={`${t('LEARN_MORE_GPU_SCRIPTS')}`}
        howCreateVideoText={`${t('HOW_CREATE_GPU_SCRIPT')}`}
        // learnMoreAboutResourceLink={`${rootConfig.docs_url}category/floating-ips`}
        howCreateVideoUrl="https://www.youtube.com/embed/3ffI9KM4pbs"
        videoImage={Illustration}
        videoImageDark={IllustrationDark}
        rightButtonComponentCustomWithResources={
          <div style={{display:'flex', alignItems:'center'}}>
            <Button
              onClick={() => {
                setAlertTitle(t("GPU_SCRIPTS"))
                setAlertSubTitle(allChecked ? ips : checked)
                openModal('delete')
              }}
              variant='icon'
              size='display'
              test_id='button-delete'
              disabled={!allChecked && checked?.length === 0 }
              customStyles={{margin:'0px 6px 0px 0px'}}
            >
              <img src={icons.DeleteIcon}/>
            </Button>
          </div>
        }
        tableData={{
          columns:Columns,
          data:ips,
          props:items,
          allChecked:allChecked,
          setAllChecked:setAllChecked,
          checked:checked,
          setChecked:editChecked,
          updateData:_updateIps,
        }}
        hideQuotas
      />
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        loading={loading}
        loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
        dataToEdit={editedIp}
      />
    </>
  )
}


export default GpuServers

