import styled from 'styled-components'
import { HeadLine1, VerySmall } from '../../components/typography/styled'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  padding: 10px 30px;
`
export const NavWrapper = styled.div`
  border-bottom: ${({theme}) => `1px solid ${theme.grey[350]}`};
  position: relative;
  display: flex;
  margin-top:40px;
  margin-bottom:20px;
  &>div{
    position: absolute;
    bottom:-1px;
    padding-left:30px;
    left:0px;
  }
`

export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`