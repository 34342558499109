import styled from "styled-components";
import { BodyDefault, BodySmall, HeadLine1, VerySmall } from "../../../../../components/typography/styled";


export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`
export const BodyForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  /* height: 852px; */
`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
  margin-bottom: 20px;
  margin-top: 20px;
`

export const InputsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 390px;
  justify-content: space-between;
  margin-top: 20px;
  gap:20px;
  margin-bottom:20px;
`


export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`

export const SelectWrapper = styled.div`
  /* display: flex; */
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  /* min-height: 144px; */
  border: ${({theme}) => `1px solid ${theme.palette[20]}`};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom:20px;
`

export const SelectRow = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
`

export const RefreshWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  margin-left: 8px;
  gap:8px;
  height: 52px;
  &:hover{
    cursor: pointer;
  }

`
export const RefreshText = styled(BodyDefault)`
  color:${({theme}) => theme.primary[600]};
`
type ImgProps = {
  active?:boolean;
}
export const RotateImg = styled.img<ImgProps>`
  animation: ${({active}) => active ? 'rotating 0.8s linear infinite' : ''};
  @keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`