import React, { FC, useEffect, useState } from 'react'
import {Container} from './styles'

type ConsoleProps = {
  instance:any
}
const Console:FC<ConsoleProps> = ({instance}) => {
  const [url, setUrl] = useState<string>(`https://ermes.cloudcenter.seeweb.it/console/ecs/${instance?.name}`)
  console.log('instanceConsole',instance);
  return(                                 
    <Container>
        <iframe width={'100%'} height={'800px'}
        src={url}>

        </iframe>
    </Container>
  )
}

export default Console