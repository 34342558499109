import React, { FC, useEffect, useState } from "react";
import {
  Container,
} from './styles'
import { Button, InfoBlock, TopProgress } from '../../../../../components'
import { useQueryParam, StringParam } from 'use-query-params';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
//ToDo Delete imports with files
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import { useInstance } from "../../../../../context/instanceContext";
import { gpuService } from "../../../../../api";
import { useUi } from "../../../../../context/ui.context";
import { useAuth } from "../../../../../context/user.context";
import { useGpu } from "../../../../../context/gpuContext";

const FinallyCreate:FC = () => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const [localLoading, setLocalLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const {setDataToLs, getDataToLs, removeItemFromLs} = useLocalStorage()
  const {viewAlert} = useUi()
  const {
    configureData,
    setConfigureData,
    pageLoading,
    plans,
  } = useGpu()
  const {
    sshKeys
  } = useInstance()
  useEffect(() => {
    if(!configureData) {
      setConfigureData(getDataToLs({key:'gpu.configure'}))
    }
    setLocalLoading(false)
  },[])
  const {user} = useAuth()
  const [step, setStep] = useQueryParam('step', StringParam);
  const plan = plans?.find((plan:any) => plan.value === configureData?.type)
  const configure = [
    {
      title:t("NAME_OF_INSTANCE"),
      value:configureData?.name,
    },
    {
      title:t("TYPE_OF_INSTANCE"),
      value:plan?.label,
      options:plan?.options,
    },
    {
      title:t("OS"),
      value:plan?.os_availables.find((os:any) => os.value === configureData?.os)?.name,
      icon:plan?.os_availables.find((os:any) => os.value === configureData?.os)?.icon,
    },
    {
      title:t("SSH_KEY"),
      value:sshKeys.find((sshKey:any) => sshKey.value === configureData.ssh)?.label || '-',
    },
  ]
  const handleCreateInstance = async () => {
    setLoading(true);
    const createInstance = async (data: any) => {
      try {
        const res = await gpuService.createServer(data);
        await setLoadingComplete(true);
        await setTimeout(() => {
          setLoadingComplete(false);
          setLoading(false);
          removeItemFromLs('gpu.configure');
          navigate('/gpu/servers');
        }, 1000);
      } catch (e: any) {
        setLoading(false);
        setLoadingComplete(false);
        if (e?.response?.data?.detail) {
          viewAlert({
            severity: 'error',
            message: e.response.data.detail,
          });
        }
      }
    };
    const data: any = {
      "plan": configureData?.type,
      "location": "it-fr2",
      "image": configureData?.os,
      "label": configureData?.name,
      "ssh_key": configureData?.ssh,
      "password": configureData?.password,
      "script_id": configureData?.script_id,
      "env": configureData?.env,
    };
    await createInstance(data);
  };

  if(pageLoading || localLoading) return <TopProgress loading={pageLoading || localLoading}/>
  return(
    <Container data-test-id='container-finally-instance' >
      <InfoBlock  title={t("CONFIGURE_INSTANCE")} data={configure}/>
      <div style={{display:'flex', marginTop:'30px'}}>
        <Button
          variant="primary"
          size="display"
          customStyles={{marginRight:"10px"}}
          onClick={handleCreateInstance}
          loading={loading}
          loadingComplete={loadingComplete}
          title={t("CREATE")}
        />
        <Button
          variant="stroke"
          size="display"
          onClick={() => setStep("configure")}
          title={t("BACK")}
        />
      </div>
    </Container>
  )
}

export default FinallyCreate