import styled from 'styled-components'
import { BodyDefault, BodySmall, VerySmall } from '../typography/styled';

type SelectProps = {
  active:boolean;
  isSelectedValue:boolean;
  isSmall?:boolean;
  disabled?:boolean;
  multi?:boolean;
  error?:boolean;
  isRegion?:boolean;
}

type ActiveProp = {
  active?:boolean;
  isSmall?:boolean;
}
type DataWrapperProps = { 
  isSmall?:boolean;
  customStyles?:any;
  height?:any;
  multi?:boolean;
}
type FlavorTypeItem = {
  block?:boolean
}
export const Container = styled.div<DataWrapperProps>`
  position: relative;
  /* display: flex;
  flex-direction: column; */
  /* height:100%; */
  ${({customStyles}) => customStyles}
`
export const ToolTipImg = styled.img`
  margin-left:4px;
  &:hover{
    cursor: pointer;
  }
`
export const DropDown = styled.div<SelectProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: ${({isRegion}) => isRegion ? '' : 'center'};
  flex-direction:${({isRegion}) => isRegion ? 'column' : ''};
  /* flex-wrap:wrap; */
  width: 100%;
  max-height:${({multi, isSmall}) =>  multi ? '' : isSmall ? '24px' : '40px'};
  min-height: ${({isSmall}) => isSmall ? '24px' : '40px'};
  background-color: ${({theme, disabled}) => disabled ? theme.palette[200] : theme.palette[10]};
  padding: ${({isSmall, multi, isRegion}) => isRegion ? '4px 8px 4px 10px' : multi ? '4px 10px' : isSmall ? '12px 4px 12px 6px' : '12px 8px 12px 10px'};

  border: ${({theme, active, disabled, error}) => disabled ? `1px solid ${theme.palette[350]}` : error ? `1px solid ${theme.errors[600]}` : active ? theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}` : `1px solid ${theme.palette[20]}`};
  box-shadow: ${({active, theme, disabled, error}) => disabled ? '' : active ? error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' :  '0px 0px 0px 3px #E0EAFF' : ''};
  border-radius: 4px;
  color: ${({theme, isSelectedValue}) => isSelectedValue ? theme.grey[900] : theme.grey[600]};
  &:hover{
    cursor: pointer;
    color: ${({theme}) => theme.grey[900]};
  }
  &:target{
    /* box-shadow: ${({disabled}) => disabled ? '' : '0px 0px 0px 3px #E0EAFF'}; */
    border: ${({theme, error}) => error ? `1px solid ${theme.errors[600]}` : theme.isDark ? `1px solid #4671F6` : `1px solid ${theme.primary[300]}`};
    box-shadow: ${({theme, error}) => error ? theme.isDark ? '0px 0px 0px 3px rgb(205,58,50, 0.4)' : `0px 0px 0px 3px ${theme.errors[100]}` : theme.isDark ? '0px 0px 0px 3px rgba(70, 113, 246, 0.5)' : `0px 0px 0px 3px #E0EAFF`};
  }
`

export const ImgArrow = styled.img<ActiveProp>`
  transform: ${({active}) => active ? 'rotate(180deg)' : ''};
  transition:0.2s transform ease-in-out;
`

export const OptionsWrapper = styled.div<DataWrapperProps>`
  /* top: ${({isSmall, multi, height}) => multi ? `${height + 7}px` : isSmall ? '31px' : '70px'}; */
  /* bottom: 0px; */
  /* top:auto; */
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 15;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 2px 2px ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[400]};
    border-width: 0 2px 0 2px;
    border-color: rgba(28,28,28,0);
    border-style: solid;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    margin: 12px 0 12px 0;
  }
  ::-webkit-scrollbar-button{
    display: none;
  }
  background-color: ${({theme}) => theme.palette[10]};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  filter: ${({theme}) => theme.isDark ? 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.5));' : ''};
  max-height: 354px;
`
export const Option = styled.div<ActiveProp>`
  box-sizing: border-box;
  width: 100%;
  height: ${({isSmall}) => isSmall ? '24px' : ''};
  display: flex;
  /* border-radius: 4px; */
  border-radius: 4px;
  padding: ${({isSmall}) => isSmall ? '2px 8px 2px 10px' : '12px 8px 12px 10px'};
  color:${({theme}) => theme.grey[850]};
  background-color: ${({theme, active}) => active ? theme.grey[300] : ''};
  &:hover{
    cursor: pointer;
    background-color: ${({theme}) => theme.isDark ? theme.palette[200] : theme.grey[100]};
  }
`
export const ItemTextTitle = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`
export const ItemText = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
  overflow: hidden !important;
  text-overflow: ellipsis;
`
export const ItemTextSmall = styled(BodySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 3px 4px;
  height:21px;
  background-color: ${({theme}) => theme.palette[300]};
  border-radius: 4px;
  align-items: center;
`
export const Prop = styled(VerySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropMulti = styled(BodySmall)`
  color:${({theme}) => theme.palette[850]};
`
export const PropsWrapper = styled.div`
  display:flex;
  flex-wrap: wrap;
  width:100%;
  gap:6px;
`

export const SelectInput = styled.input`
  width:50px;
  height:27px;
  border:none;
  outline: none;
  color:${({theme}) => theme.base.black};
  background-color:${({theme}) => theme.palette[10]};
  /* z-index: 22; */
`

export const ItemFlavor = styled.div<FlavorTypeItem>`
  flex-wrap:wrap;
  gap:6px;
  width:100%;
  display:flex;
  align-items:center;
  justify-content:space-between;
  opacity: ${({block}) => block ? '0.5' : ''};
`
export const Categorytitle = styled(VerySmall)`
  color:${({theme}) => theme.grey[600]};
  text-transform: uppercase;
`